// a little JS for the mobile nav button
if (document.getElementById('nav-mobile-btn')) {
		document.getElementById('nav-mobile-btn').addEventListener('click', function () {
				if (this.classList.contains('close')) {
						document.getElementById('nav').classList.add('hidden');
						this.classList.remove('close');
				} else {
						document.getElementById('nav').classList.remove('hidden');
						this.classList.add('close');
				}
		});
}
